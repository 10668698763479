import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { message, Spin } from "antd";
import { ceil, isEmpty, isEqual } from "lodash";
import get from "lodash.get";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { connect } from "react-redux";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getTransfers from "../../../newApi/asset/transfer/getTransfers";
import { triggerModal } from "../../../redux/actions/app-actions";
import { updateTransferActiveId } from "../../../redux/actions/product-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { formatNumber } from "../../../utilities/common-function";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import { getFormatedDate } from "../config";

const InterTransferList = (props) => {
  const accessKey = get(props.user, "accessKey");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [transferList, setTransferList] = useState([]);
  const user = get(props, "user.user");
  const isAuthenticated = get(props, "user.isAuthenticated");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const PAGE_SIZE = 50;

  const getData = (key) => {
    setLoading(true);

    if (isEmpty(get(user, "_id")) || !isAuthenticated) {
      message.error("User not Found.");
      setLoading(false);
    } else {
      getTransfers(
        PAGE_SIZE,
        (page - 1) * PAGE_SIZE,
        {
          getSenderReceiverSameProfileId: get(user, "profile._id"),
        },
        accessKey
      )
        .then((res) => {
          if (get(res, "data")) {
            setTransferList(get(res, "data"));
          }
          if (get(res, "total")) {
            setTotal(get(res, "total"));
          }
          setLoading(false);
        })
        .catch((err) => {
          message.error(err);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getData();
  }, [page]);

  let total_pages = ceil(total / PAGE_SIZE);

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            suffix={
              <div className="justify-end flex items-center">
                {t("transferHistory", sourceKey.asset)}
              </div>
            }
            showBack={true}
          >
            <Scrollbars style={{ height: 600 }}>
              <div className="p-4" style={{ minHeight: 200 }}>
                {transferList.map((record, index) => {
                  return (
                    <div
                      key={index}
                      className="flex justify-between items-center my-5 mx-3 border-b pb-2"
                      style={{ cursor: "default" }}
                    >
                      <span className=" inline-block w-2/3">
                        <strong>
                          {isEqual(
                            get(record, "senderId"),
                            get(user, "profile._id")
                          )
                            ? t("transferTo", sourceKey.asset)
                            : t("receiveFrom", sourceKey.asset)}
                        </strong>
                        {/* <br /> */}
                        {/* {isEqual(
                          get(record, "senderId"),
                          get(user, "profile._id")
                        )
                          ? get(record, "populatedReceiverUserId.referralCode")
                          : get(
                              record,
                              "populatedSenderUserId.referralCode"
                            )}{" "} */}
                        <span className="pl-1">
                          {isEqual(
                            get(record, "senderId"),
                            get(user, "profile._id")
                          )
                            ? get(record, "populatedReceiverUserId.username")
                            : get(record, "populatedSenderUserId.username")}
                        </span>

                        <br />
                        {get(record, "transactionDate")
                          ? getFormatedDate(record?.transactionDate, true)
                          : ""}
                      </span>

                      <span className=" inline-block w-2/3">
                        <div
                          className="flex justify-end items-center"
                          style={{
                            color: `${
                              isEqual(
                                get(record, "senderId"),
                                get(user, "profile._id")
                              )
                                ? "red"
                                : "limegreen"
                            }`,
                          }}
                        >
                          {`${
                            isEqual(
                              get(record, "senderId"),
                              get(user, "profile._id")
                            )
                              ? "-"
                              : "+"
                          }`}
                          {formatNumber(
                            get(record, "amount"),
                            null,
                            true,
                            8,
                            true
                          )}
                          {" USDT"}
                        </div>
                      </span>
                    </div>
                  );
                })}
              </div>
            </Scrollbars>
            {total_pages > 1 ? (
              <div style={{ textAlign: "right" }}>
                <LeftOutlined
                  style={{ marginRight: 20, cursor: "pointer" }}
                  onClick={() => {
                    if (page > 1) {
                      setPage(page - 1);
                    } else {
                      message.error("You are on the first page.");
                    }
                  }}
                />
                {`${page} out of ${total_pages === 0 ? 1 : total_pages} pages`}

                <RightOutlined
                  style={{ marginLeft: 20, cursor: "pointer" }}
                  onClick={() => {
                    if (page !== total_pages && total_pages !== 0) {
                      setPage(page + 1);
                    } else {
                      message.error("You are on the last page.");
                    }
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
  updateTransferActiveId,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterTransferList);
