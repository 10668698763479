import { isPlainObject } from "lodash";
import { apiUrl, _axios_base_get_list } from "../../index";
import { depositApiPrefix } from "../index";

export default async function getTransfers(
  limit = 99,
  skip = 0,
  query = {},
  accessKey = ""
) {
  if (!isPlainObject(query)) {
    query = {};
  }

  return _axios_base_get_list(
    `${apiUrl}/${depositApiPrefix}/getTransfers`,
    limit,
    skip,
    query,
    accessKey
  );
}
